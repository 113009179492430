import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "dynamic_insurance_terms_modal",
  tabindex: "-1",
  "aria-hidden": "true",
  "data-bs-backdrop": "static"
}
const _hoisted_2 = { class: "modal-dialog modal-xl modal-dialog-scrollable" }
const _hoisted_3 = { class: "modal-content rounded" }
const _hoisted_4 = { class: "modal-header justify-content-end border-0 pb-0" }
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body pt-0 pb-15 px-5 px-xl-20" }
const _hoisted_8 = { class: "mb-5 text-center" }
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = { class: "text-gray-400 fw-bold fs-5" }
const _hoisted_11 = { class: "d-flex flex-column" }
const _hoisted_12 = { class: "row mt-10" }
const _hoisted_13 = { class: "col-lg-4 mb-10 mb-lg-0" }
const _hoisted_14 = { class: "nav flex-column" }
const _hoisted_15 = ["data-bs-target"]
const _hoisted_16 = { class: "d-flex align-items-center me-2" }
const _hoisted_17 = { class: "form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6" }
const _hoisted_18 = ["value"]
const _hoisted_19 = { class: "flex-grow-1" }
const _hoisted_20 = { class: "d-flex align-items-center fs-4 fw-bold flex-wrap" }
const _hoisted_21 = { class: "col-lg-8" }
const _hoisted_22 = { class: "tab-content rounded h-100 bg-light p-10" }
const _hoisted_23 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Close.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.name), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.details.businessClass) + " Insurance: " + _toDisplayString(_ctx.details.name) + " Policy Terms & Conditions ", 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.termsAndConditions, (item, index) => {
                    return (_openBlock(), _createElementBlock("label", {
                      key: index,
                      class: _normalizeClass(["nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 active mb-6", { 'btn-active btn-active-primary': index === _ctx.selectedTermIndex }]),
                      "data-bs-toggle": "tab",
                      "data-bs-target": `#${item.publicId}`
                    }, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-check-input",
                            type: "radio",
                            name: "selectedTerm",
                            value: index,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTermIndex) = $event))
                          }, null, 8, _hoisted_18), [
                            [_vModelRadio, _ctx.selectedTermIndex]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, _toDisplayString(item.name) + " Cover ", 1)
                        ])
                      ])
                    ], 10, _hoisted_15))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.termsAndConditions, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["tab-pane fade", { 'show active': index === _ctx.selectedTermIndex }]),
                      id: "comprehensive_terms",
                      key: index
                    }, [
                      _createElementVNode("div", {
                        class: "pt-1",
                        innerHTML: item.terms
                      }, null, 8, _hoisted_23)
                    ], 2))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}